import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import { ProductsNavigation } from '../components/Products/ProductsNavigation';
import { FormBlock } from '../acf/FormBlock/FormBlock.jsx';
import { ProductSearch } from '../acf/ProductSearch/ProductSearch.jsx';
import { ProductsTiles } from '../components/Products/ProductsTiles'
import './products.scss'

export default class IndexPage extends React.Component {
  render() {
    const {
      data,
      pageContext,
      location
    } = this.props;
    const {
      site: { siteMetadata: { siteTitle } },
      productCategories
    } = data;
    return (
      <Layout
        location={location}
        pageTitle={pageContext.slug}
        className="products-template"
      >
        <SEO
          title={`Products | ${siteTitle}`}
          desc=""
        />
        <ProductsNavigation productCategories={productCategories} location={location} />
        <ProductSearch
          heading="We represent over 40 original equipment manufacturers."
          placeholder="Click here to search for 30,000+ products"
          fancyLabel="Search"
          includeFilters={false}
        />
        <div className="products-tiles-container">
          <div className="wrapper">
            <h2 className="products-tiles-container-heading">
              Or choose from our main products:
            </h2>
            <ProductsTiles productCategories={productCategories} location={location} />
          </div>
        </div>
        <FormBlock location={location} />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ProductsPageQuery {
    site {
      siteMetadata {
        siteTitle: title
      }
    }
    productCategories: allWordpressWpProductCategory(sort: {order: ASC, fields: title}) {
      nodes {
        slug
        link
        acf {
          thumb
          blurb
          categoryTitle
          image
        }
        wordpress_id
        wordpress_parent
      }
    }
  }
`
